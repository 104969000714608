import router from "./router";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css";
import { useConfigStore } from "@/store/config"; // progress bar style
const { VITE_CLIENT_VERSION } = import.meta.env;

NProgress.configure({ showSpinner: false }); // NProgress Configuration

// const whiteList = ["/screencastLinkage", "/nonactivated", "/subWind"];
const whiteList = ["/nonactivated", "/znkf-ai-guide"];

router.beforeEach(async (to, from, next) => {
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    const configStore = useConfigStore();
    if (!configStore.isLoading) {
      await configStore.getConfig();
      checkClientVersion(configStore.clientVersion);
    }

    if (configStore.status == 3) {
      if (configStore.isTimeout) {
        next({ path: `/nonactivated` });
      }
    } else if (configStore.status != 1) {
      next({ path: `/nonactivated` });
    } else {
      next();
    }
    NProgress.done();
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});

const checkClientVersion = (clientVersion) => {
  if (VITE_CLIENT_VERSION != clientVersion) {
    // 记录已经刷新过了，防止一直刷新
    var localVersion = localStorage.getItem("refreshVersion");
    if (localVersion == clientVersion) {
      return;
    }
    localStorage.setItem("refreshVersion", clientVersion);
    location.reload();
  }
};
